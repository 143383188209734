import React from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import projectsStyles from '../style/projects.module.scss'
import Image from "../components/global/image.js"

import ShortInfo from "../components/projects/projects.shortinfo.js"
import Description from "../components/projects/projects.description.js"
import Navigation from "../components/projects/projects.navigation.js"

import img1 from "../img/projects/Teaser_IM.jpg"
import img2 from "../img/projects/Mockup_IM.png"


const InfinityMedia = () => {

    const intl = useIntl()

    const subTitleObject = <p>{intl.formatMessage({ id: "Projects.clients.infinitymedia" })}</p>
    const subTitle = subTitleObject.props.children + " | 2020"

    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Digital Signage</title>
                <meta name="description" content="Full-stack Software Design und Development - Ein Einblick in unsere Lieblingsprojekte"></meta>
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Project.h1" />}
                title={<FormattedMessage id="Projects.digitalsignage" />}
                subtitle={subTitle}
            />

            <ContentSection>

                <ShortInfo
                    client={<FormattedMessage id="Projects.clients.infinitymedia" />}
                    year={2020}
                    services={[
                        <FormattedMessage id="Services.development" />,
                        <FormattedMessage id="Services.infrastructure" />,
                    ]}
                />
                <Description
                    project={<FormattedMessage id="Projects.digitalsignage.project" />}
                    contribution={<FormattedMessage id="Projects.digitalsignage.contribution" />}
                    technologies={[
                        <FormattedMessage id="Projects.technologies.js" />,
                        <FormattedMessage id="Projects.technologies.ts" />,
                        <FormattedMessage id="Projects.technologies.node" />,
                        <FormattedMessage id="Projects.technologies.vue.js" />,
                        <FormattedMessage id="Projects.technologies.postgre" />,
                        <FormattedMessage id="Projects.technologies.debian" />,
                        <FormattedMessage id="Projects.technologies.nomad" />,
                        <FormattedMessage id="Projects.technologies.vault" />,
                        <FormattedMessage id="Projects.technologies.docker" />,
                        <FormattedMessage id="Projects.technologies.consul" />,
                        <FormattedMessage id="Projects.technologies.terraform" />,
                        <FormattedMessage id="Projects.technologies.haproxy" />,
                        <FormattedMessage id="Projects.technologies.nginx" />,
                        <FormattedMessage id="Projects.technologies.gluster" />]}
                    img1={<Image className={projectsStyles.projectImage} src={img1} alt="Projects.digitalsignage.img1.alt" />}
                    img2={<Image className={projectsStyles.floatingProjectImage} src={img2} alt="Projects.digitalsignage.img2.alt" />}
                />

                <Navigation

                    prevName={<FormattedMessage id="Projects.exhibitorcatalogue" />}
                    prevClient={<FormattedMessage id="Projects.clients.tradex" />}
                    prevYear="2021"
                    prevUrl="../project_exhibitorcatalogue"

                    nextName={<FormattedMessage id="Projects.emticker" />}
                    nextClient={<FormattedMessage id="Projects.clients.infinitymedia" />}
                    nextYear="2021"
                    nextUrl="../project_emticker" />

            </ContentSection>
        </Layout>


    )
}

export default InfinityMedia
